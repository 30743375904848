<script lang="ts">
	export let width = 32;
	export let height = 32;
	export let darkMode = false;
	export let color = '#0F0F0F';

	$: color = darkMode ? 'white' : '#0F0F0F';
</script>

<svg {width} {height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M5.3335 13.3333V10.6667C5.3335 9.19391 6.52741 8 8.00017 8H24.0002C25.4729 8 26.6668 9.19391 26.6668 10.6667V13.3333M5.3335 13.3333V21.3333C5.3335 22.8061 6.5274 24 8.00016 24H24.0002C25.4729 24 26.6668 22.8061 26.6668 21.3333V13.3333M5.3335 13.3333H26.6668M9.3335 20H16.0002"
		stroke={color}
		stroke-width="1.33333"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
