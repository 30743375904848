<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M16.9287 11.7887C17.5329 11.6055 18.2418 11.5 19 11.5C21.2091 11.5 23 12.3954 23 13.5C23 14.6046 21.2091 15.5 19 15.5C18.2418 15.5 17.5329 15.3945 16.9287 15.2113M16.9287 11.7887C15.8449 11.0098 14.0415 10.5 12 10.5C9.95848 10.5 8.1551 11.0098 7.07131 11.7887M16.9287 11.7887C17.6041 12.2741 18 12.8639 18 13.5C18 14.1361 17.6041 14.7259 16.9287 15.2113M16.9287 15.2113C15.8449 15.9902 14.0415 16.5 12 16.5C9.95848 16.5 8.1551 15.9902 7.07131 15.2113M7.07131 11.7887C6.46714 11.6055 5.7582 11.5 5 11.5C2.79086 11.5 1 12.3954 1 13.5C1 14.6046 2.79086 15.5 5 15.5C5.7582 15.5 6.46714 15.3945 7.07131 15.2113M7.07131 11.7887C6.39594 12.2741 6 12.8639 6 13.5C6 14.1361 6.39594 14.7259 7.07131 15.2113M15 3.5C15 5.15685 13.6569 6.5 12 6.5C10.3431 6.5 9 5.15685 9 3.5C9 1.84315 10.3431 0.5 12 0.5C13.6569 0.5 15 1.84315 15 3.5ZM21 6.5C21 7.60457 20.1046 8.5 19 8.5C17.8954 8.5 17 7.60457 17 6.5C17 5.39543 17.8954 4.5 19 4.5C20.1046 4.5 21 5.39543 21 6.5ZM7 6.5C7 7.60457 6.10457 8.5 5 8.5C3.89543 8.5 3 7.60457 3 6.5C3 5.39543 3.89543 4.5 5 4.5C6.10457 4.5 7 5.39543 7 6.5Z"
		stroke="url(#paint0_linear_2482_796)"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_2482_796"
			x1="1"
			y1="0.5"
			x2="22.666"
			y2="5.16022"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
