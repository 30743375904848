<script lang="ts">
	export let darkMode = false;
	export let width = 32;
	export let height = 32;
</script>

{#if darkMode}
	<svg {width} {height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.6667 18.6667L16 16V9.33333M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
			stroke="white"
			stroke-width="1.33333"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
{:else}
	<svg {width} {height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.6667 18.6667L16 16V9.33333M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
			stroke="#0F0F0F"
			stroke-width="1.33333"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
{/if}
