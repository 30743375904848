<script lang="ts">
	export let darkMode = false;
</script>

{#if darkMode}
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M22.5718 20.3848C23.3773 20.1406 24.3226 19.9999 25.3335 19.9999C28.279 19.9999 30.6668 21.1938 30.6668 22.6666C30.6668 24.1393 28.279 25.3333 25.3335 25.3333C24.3226 25.3333 23.3773 25.1926 22.5718 24.9483M22.5718 20.3848C21.1267 19.3463 18.7222 18.6666 16.0002 18.6666C13.2781 18.6666 10.8736 19.3463 9.42857 20.3848M22.5718 20.3848C23.4722 21.032 24.0002 21.8185 24.0002 22.6666C24.0002 23.5147 23.4722 24.3012 22.5718 24.9483M22.5718 24.9483C21.1267 25.9868 18.7222 26.6666 16.0002 26.6666C13.2781 26.6666 10.8736 25.9868 9.42857 24.9483M9.42857 20.3848C8.62301 20.1406 7.67777 19.9999 6.66683 19.9999C3.72131 19.9999 1.3335 21.1938 1.3335 22.6666C1.3335 24.1393 3.72131 25.3333 6.66683 25.3333C7.67777 25.3333 8.62301 25.1926 9.42857 24.9483M9.42857 20.3848C8.52808 21.032 8.00016 21.8185 8.00016 22.6666C8.00016 23.5147 8.52808 24.3012 9.42857 24.9483M20.0002 9.33325C20.0002 11.5424 18.2093 13.3333 16.0002 13.3333C13.791 13.3333 12.0002 11.5424 12.0002 9.33325C12.0002 7.12411 13.791 5.33325 16.0002 5.33325C18.2093 5.33325 20.0002 7.12411 20.0002 9.33325ZM28.0002 13.3333C28.0002 14.806 26.8063 15.9999 25.3335 15.9999C23.8607 15.9999 22.6668 14.806 22.6668 13.3333C22.6668 11.8605 23.8607 10.6666 25.3335 10.6666C26.8063 10.6666 28.0002 11.8605 28.0002 13.3333ZM9.3335 13.3333C9.3335 14.806 8.13959 15.9999 6.66683 15.9999C5.19407 15.9999 4.00016 14.806 4.00016 13.3333C4.00016 11.8605 5.19407 10.6666 6.66683 10.6666C8.13959 10.6666 9.3335 11.8605 9.3335 13.3333Z"
			stroke="white"
			stroke-width="1.33333"
		/>
	</svg>
{:else}
	<svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M22.5718 16.3848C23.3773 16.1406 24.3226 15.9999 25.3335 15.9999C28.279 15.9999 30.6668 17.1938 30.6668 18.6666C30.6668 20.1393 28.279 21.3333 25.3335 21.3333C24.3226 21.3333 23.3773 21.1926 22.5718 20.9483M22.5718 16.3848C21.1267 15.3463 18.7222 14.6666 16.0002 14.6666C13.2781 14.6666 10.8736 15.3463 9.42857 16.3848M22.5718 16.3848C23.4722 17.032 24.0002 17.8185 24.0002 18.6666C24.0002 19.5147 23.4722 20.3012 22.5718 20.9483M22.5718 20.9483C21.1267 21.9868 18.7222 22.6666 16.0002 22.6666C13.2781 22.6666 10.8736 21.9868 9.42857 20.9483M9.42857 16.3848C8.62301 16.1406 7.67777 15.9999 6.66683 15.9999C3.72131 15.9999 1.3335 17.1938 1.3335 18.6666C1.3335 20.1393 3.72131 21.3333 6.66683 21.3333C7.67777 21.3333 8.62301 21.1926 9.42857 20.9483M9.42857 16.3848C8.52808 17.032 8.00016 17.8185 8.00016 18.6666C8.00016 19.5147 8.52808 20.3012 9.42857 20.9483M20.0002 5.33325C20.0002 7.54239 18.2093 9.33325 16.0002 9.33325C13.791 9.33325 12.0002 7.54239 12.0002 5.33325C12.0002 3.12411 13.791 1.33325 16.0002 1.33325C18.2093 1.33325 20.0002 3.12411 20.0002 5.33325ZM28.0002 9.33325C28.0002 10.806 26.8063 11.9999 25.3335 11.9999C23.8607 11.9999 22.6668 10.806 22.6668 9.33325C22.6668 7.86049 23.8607 6.66659 25.3335 6.66659C26.8063 6.66659 28.0002 7.86049 28.0002 9.33325ZM9.3335 9.33325C9.3335 10.806 8.13959 11.9999 6.66683 11.9999C5.19407 11.9999 4.00016 10.806 4.00016 9.33325C4.00016 7.86049 5.19407 6.66659 6.66683 6.66659C8.13959 6.66659 9.3335 7.86049 9.3335 9.33325Z"
			stroke="currentColor"
			stroke-width="1.33333"
		/>
	</svg>
{/if}
